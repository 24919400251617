<template>
  <div class="container pr" v-if="isFinsh">
    <!-- 坐标城市 -->
    <div
      class="address pa u-flex u-col-center u-row-between"
      @click="handleClickPosition"
      v-cloak
    >
      <img class="icon-position" src="../assets/images/icon_position.png" />
      <span>{{ addressPositionName }}</span>
    </div>
    <div class="backBtn" @click="backBtnDidAction" v-show="showBackBtn"></div>

    <!-- 顶部数字滚动 -->
    <div class="round-con pr">
      <div class="round-div pr" :class="stepLineMove ? 'step-line-move' : ''">
        <img src="../assets/images/icon_round.png" class="round-img pr" />
      </div>
      <!-- 刻度 -->
      <ul class="scale pa">
        <li
          v-for="item in 24"
          :key="item"
          :style="{
            transform: `rotate(${item * 15}deg)`,
          }"
        />
      </ul>

      <div class="round-cont-info pa u-flex-col u-col-center u-row-center">
        <!-- logo -->
        <img v-if="logo" class="logo" :src="logo" />
        <img v-else class="logo" src="../assets/images/rong_logo.png" />

        <!-- 最高可领取额度 -->
        <p>最高可领取额度（元）</p>

        <div class="pr">
          <count-to
            :start-val="0"
            :end-val="200000"
            :duration="2000"
            class="pr"
          />
          <!-- 网格线背景 -->
          <img
            class="pa line-solid-bg"
            src="../assets/images/line_solid_bg.png"
          />
        </div>
      </div>
    </div>

    <!-- 最长免息30天 -->
    <p class="u-text-center interest-free">
      请填写真实的身份信息，提交后将无法修改
    </p>

    <!-- 注册表单 -->
    <div class="register-form">
      <!-- 姓名 -->
      <van-cell-group class="inp-elem-box">
        <van-field
          v-model.trim="form.userName"
          maxlength="4"
          class="inp-elem"
          placeholder="请输入您的真实姓名"
          @blur="handleBlur(0)"
          clearable
        />
      </van-cell-group>

      <!-- 年龄 -->
      <van-cell-group
        class="inp-elem-box"
        v-if="form.channelNo.indexOf('TUOTIAO_FORM_') > -1"
      >
        <van-field
          v-model.trim="form.age"
          type="digit"
          maxlength="2"
          class="inp-elem"
          placeholder="请输入您的年龄"
          @blur="handleBlur(2)"
          clearable
        />
      </van-cell-group>

      <!-- 身份证号 -->
      <van-cell-group class="inp-elem-box" v-else>
        <van-field
          v-model.trim="form.idCard"
          maxlength="18"
          class="inp-elem"
          placeholder="请输入您的身份证号"
          @blur="handleBlur(1)"
          clearable
        />
      </van-cell-group>

      <!-- 信用信息 -->
      <div class="inp-elem-box inp-elem tags">
        <div class="label-left">
          信用信息
          <span>(多选)</span>
          <small>
            资产<span class="highlight">越多</span>，通过率
            <span class="highlight">越高</span>，额度
            <span class="highlight">越大</span>
          </small>
        </div>
        <van-row :gutter="5">
          <van-col
            v-for="(item, index) in formItemData.creditType"
            :key="index"
            :class="item.active ? 'active' : ''"
            span="8"
            @click="handleSelectCreditInfo(index)"
          >
            <div class="u-flex u-col-center u-row-center">
              {{ item.dictLabel }}
            </div>
          </van-col>
        </van-row>
      </div>

      <!-- 芝麻分 -->
      <div class="inp-elem-box inp-elem tags">
        <div class="label-left">芝麻分</div>
        <van-row :gutter="5">
          <van-col
            v-for="(item, index) in formItemData.credit"
            :key="index"
            :class="item.active ? 'active' : ''"
            span="12"
            @click="handleChooseCredit(index)"
          >
            <div class="u-flex u-col-center u-row-center">
              {{ item.dictLabel }}
            </div>
          </van-col>
        </van-row>
      </div>
    </div>

    <!-- 提交 -->
    <button class="btn-submit" @click="handleSubmit">提交</button>

    <!-- 按钮底部提示 -->
    <div class="u-flex btn-submit-tips u-row-center">
      <van-icon size="0.35rem" name="bulb-o" />
      <p>数据加密保护，仅用于申请审核</p>
    </div>

    <!-- 底部 -->
    <div class="bottom u-text-center">{{ h5LoanRemindDesc }}</div>

    <!-- 提示浏览器打开遮罩层 -->
    <SharePop :show="showPop" @change="handlePopChange" />

    <!-- 工作城市下拉选 -->
    <van-popup
      v-model="showPickerCity"
      position="bottom"
      safe-area-inset-bottom
      round
    >
      <van-picker
        show-toolbar
        title="请选择工作城市"
        :columns="areaList"
        @change="onCityChange"
        @confirm="onAreaConfirm"
        @cancel="showPickerCity = false"
        value-key="localName"
      />
    </van-popup>

    <!-- 头条表单渠道注册的同意协议继续下一步的弹窗 -->
    <van-popup
      v-model="toutiaoShow"
      round
      position="bottom"
      :safe-area-inset-bottom="true"
      :close-on-click-overlay="false"
      :style="{ height: '90%' }"
    >
      <div class="toutiao-show-con">
        <p class="toutiao-pup-title">
          融e钱包将根据您的资质,为您匹配专业的贷款咨询公司（包含:
          <small>{{ companys }}</small>
          ），请查阅
          <span @click.stop="showProp('xxsq')">《个人信息共享授权书》</span>和
          <span @click.stop="showProp('zqgz')">《知情告知书》</span>
        </p>
        <div class="toutiao-pup-protocol u-text-center">
          <div class="pr" :class="xxsqHeightFlag ? '' : 'expand-height'">
            <h4>《个人信息共享授权书》</h4>
            <div class="protocol-content" v-html="xxsq"></div>
            <van-cell
              v-if="xxsqHeightFlag"
              class="pa islink"
              title="完整协议"
              is-link
              :border="false"
              :clickable="true"
              @click="handleFullAgreement('xxsq')"
            />
          </div>
          <div class="pr" :class="zqgzHeightFlag ? '' : 'expand-height'">
            <h4>《知情告知书》</h4>
            <div class="protocol-content" v-html="zqgz" />
            <van-cell
              v-if="zqgzHeightFlag"
              class="pa islink"
              title="完整协议"
              is-link
              :border="false"
              :clickable="true"
              @click="handleFullAgreement('zqgz')"
            />
          </div>
        </div>
        <div class="go-on pa u-text-center">
          <p @click="handleGoOn">同意协议并继续</p>
        </div>
      </div>
    </van-popup>

    <!-- 《个人信息共享授权书》和《知情告知书》局部弹窗 -->
    <van-popup
      v-model="showProtocol"
      round
      :style="{
        width: '80%',
        height: '70%',
      }"
      @close="handleClose"
    >
      <div class="show-protocol-con">
        <h4 class="u-text-center">{{ loaclTitle }}</h4>
        <div class="show-protocol-info protocol-content" v-html="showContent" />
        <div
          class="show-protocol-btn u-text-center"
          @click="showProtocol = false"
        >
          关闭
        </div>
      </div>
    </van-popup>

    <!-- 弹窗 -->
    <div class="box_8 flex-col" v-show="showPopProduct" v-if="this.product">
      <div class="section_11 flex-col">
        <div class="box_9 flex-row justify-between">
          <van-checkbox v-model="rpChecked" shape="square" icon-size="17px">
          </van-checkbox>
          <div class="text-wrapper_10">
            <span class="text_20">我已阅读并同意</span>
            <span class="text_21" @click.stop="handleProtocol(9)"
              >《用户个人信息共享授权协议》</span
            >
          </div>
        </div>
        <!-- 申请按钮 -->
        <img
          class="box_10 flex-row"
          src="../assets/images/popup_product_btn.png"
          @click="rpApplyBtndDidAction"
        />

        <div class="box_11 flex-col">
          <div class="box_12 flex-row">
            <div class="group_10 flex-col"></div>
          </div>
          <div class="image-wrapper_1 flex-row">
            <img
              class="label_1"
              referrerpolicy="no-referrer"
              src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngc27f0cd2443d0f48a8e14c965e8e368fbdaf7df71af59584e8880966b3065b75"
            />
          </div>
        </div>
        <div class="box_13 flex-col">
          <div class="section_12 flex-row justify-between">
            <div
              class="icon_1 flex-col"
              :style="{
                backgroundImage:
                  'url(' +
                  (this.product.logo
                    ? this.product.logo
                    : this.product.productPlaceHolder) +
                  ')',
                backgroundSize: '27px 27px',
                backgroundRepeat: 'no-repeat',
              }"
            ></div>
            <span class="text_23" v-if="this.product">{{
              this.product.productName
            }}</span>
          </div>
          <div class="text-wrapper_12 flex-row justify-between">
            <span class="text_24">最高额度(元）</span>
            <span class="text_25">综合日利率</span>
          </div>
          <div class="section_13 flex-row justify-between">
            <div class="text-group_6 flex-col justify-between">
              <span class="text_26">{{ this.product.loanAmount }}</span>
            </div>
            <div class="text-group_7 flex-col justify-between">
              <span class="text_27">{{ this.product.loanRate }}</span>
            </div>
          </div>
          <div class="text-wrapper_13 flex-col">
            <span class="text_28">已为您匹配高额产品</span>
          </div>
        </div>
        <div class="box_14 flex-col"></div>
        <div class="section_14 flex-col">
          <div class="icon_2 flex-col" @click="reCloseBtnDidAction"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CountTo from "vue-count-to";
import SharePop from "@/components/SharePop";
import myBMap from "../utils/myBMap";
import * as API_Common from "@/api/common.js";
import { Dialog } from "vant";
window.onpageshow = function (event) {
  if (
    localStorage.getItem("isSubimit") &&
    !localStorage.getItem("isClickAuth")
  ) {
    if (event.persisted) {
      window.location.reload();
    }
  }
};
export default {
  name: "RegistrEntry",
  components: {
    CountTo,
    SharePop,
  },
  data() {
    return {
      isFinsh: false,
      positionStatus: false,
      showPopProduct: false,
      rpChecked: true,
      logo: "",
      address: {
        province: "",
        city: "",
      },
      areaList: [{ values: [] }, { values: [] }],
      showPickerCity: false,
      redirect: this.$route.query.redirect,
      created: this.$route.query.created,
      isShowView: false,
      form: {
        channelNo: this.$route.query.source,
        userName: "",
        idCard: "",
        age: "",
        credit: "",
        assets: [],
        gpsCityName: "",
        cityId: "",
        provinceId: "",
      },
      product: {
        //产品名字
        productName: "",
        //产品icon
        logo: "",
        //产品icon占位图
        productPlaceHolder:
          "https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/8d4f87aba9e348a0b8a48a06a914d47e_mergeImage.png",
        //产品最高额度
        loanAmount: "",
        //产品利率
        loanRate: "",
      },

      productList: [],
      stepLineMove: false,
      showPop: false,
      formItemData: {
        credit: [],
        creditType: [
          { dictValue: 1, dictLabel: "有房" },
          { dictValue: 2, dictLabel: "有车" },
          { dictValue: 3, dictLabel: "有公积金" },
          { dictValue: 4, dictLabel: "有社保" },
          { dictValue: 5, dictLabel: "有商业保险" },
          { dictValue: 6, dictLabel: "企业主" },
        ],
      },
      regTest: {
        regUserName: /^[\u4e00-\u9fa5]{2,6}$/,
        regIdcard:
          /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
      },
      h5LoanRemindDesc: "",
      toutiaoShow: false,
      xxsq: "",
      xxsqHeightFlag: true,
      zqgz: "",
      zqgzHeightFlag: true,
      showContent: "",
      showProtocol: false,
      companys: "",
      loaclTitle: "",
      isClickSubmit: false, //点击提交按钮不弹出alertview
      showBackBtn: false, //无产品不显示返回按钮
    };
  },

  mounted() {
    let isWeixin = this.isWeixin();
    if (isWeixin && this.form.channelNo != "wxgzh") {
      this.showPop = true;
    }
    this.checkPosition();
    setTimeout(() => {
      this.stepLineMove = true;
    }, 10);
    localStorage.removeItem("isPopupProduct");
    localStorage.removeItem("isPushAuth");
    this.queryFormItem();
    this.getLogo();
    this.getRiskWarningText();
    this.getProtocol();
    this.requestPopupProduct();
    if (
      localStorage.getItem("isSubimit") &&
      !localStorage.getItem("isClickAuth")
    ) {
      this.submitFormNextGoPage();
    }
  },
  computed: {
    addressPositionName() {
      if (this.positionStatus) {
        return `${this.address.province} ${this.address.city}`;
      } else {
        if (this.form.gpsCityName) {
          return this.form.gpsCityName;
        } else {
          // this.$toast("定位失败");
          return "定位失败";
        }
      }
    },
  },
  methods: {
    checkPosition() {
      if (navigator.geolocation) {
        this.initBMap();
      } else {
        this.$toast("当前浏览器不支持定位，请手动选择定位");
        this.manualPosition();
      }
    },

    initBMap() {
      myBMap.init().then((BMap) => {
        let that = this;
        let geolocation = new BMap.Geolocation();

        geolocation.enableSDKLocation();
        geolocation.getCurrentPosition(
          function (r) {
            if (r) {
              /*
              关于this.getStatus()返回状态码示例说明
              BMAP_STATUS_SUCCESS 检索成功。对应数值“0”
              BMAP_STATUS_CITY_LIST 城市列表。对应数值“1”
              BMAP_STATUS_UNKNOWN_LOCATION 位置结果未知。对应数值“2”
              BMAP_STATUS_UNKNOWN_ROUTE 导航结果未知。对应数值“3”
              BMAP_STATUS_INVALID_KEY 非法密钥。对应数值“4”
              BMAP_STATUS_INVALID_REQUEST 非法请求。对应数值“5”
              BMAP_STATUS_PERMISSION_DENIED 没有权限。对应数值“6”
              BMAP_STATUS_SERVICE_UNAVAILABLE 服务不可用。对应数值“7”
              BMAP_STATUS_TIMEOUT 超时。对应数值“8”
            */
              if (this.getStatus() === 0) {
                that.positionStatus = true;
                that.address.province = r.address.province;
                that.address.city = r.address.city;
                that.form.gpsCityName = that.address.city;
              } else {
                that.$toast.fail("定位失败，请手动选择城市");
                that.manualPosition();
              }
            }
          },
          function () {
            that.$toast.fail("定位失败，请手动选择城市");
            that.manualPosition();
          },
          { province: "baidu" }
        );
      });
    },

    manualPosition() {
      this.positionStatus = false;
    },

    handleClickPosition() {
      if (!this.positionStatus) {
        this.getArea(0, 0);
        this.showPickerCity = true;
      }
    },

    handleBlur(index) {
      switch (index) {
        case 0:
          if (this.form.userName !== "") {
            if (!this.regTest.regUserName.test(this.form.userName)) {
              this.$toast("请输入正确的姓名");
            }
          }
          break;
        case 1:
          if (this.form.idCard !== "") {
            if (!this.regTest.regIdcard.test(this.form.idCard)) {
              this.$toast("请输入正确的身份证号码");
            }
          }
          break;
        case 2:
          if (this.form.age !== "" && parseInt(this.form.age) === 0) {
            this.$toast("年龄至少为1");
            this.form.age = "";
          }
          break;
      }
    },

    handleSubmit() {
      if (!this.positionStatus && this.form.cityId === "") {
        this.$toast("请选择您所在的城市");
        return;
      } else if (this.form.userName === "") {
        this.$toast("请输入姓名");
        return;
      } else if (!this.regTest.regUserName.test(this.form.userName)) {
        this.$toast("请输入合法的姓名");
        return;
      }

      if (this.form.channelNo.indexOf("TUOTIAO_FORM_") > -1) {
        if (this.form.age === "") {
          this.$toast("请输入年龄");
          return;
        }
      } else {
        if (this.form.idCard === "") {
          this.$toast("请输入身份证号码");
          return;
        } else if (!this.regTest.regIdcard.test(this.form.idCard)) {
          this.$toast("请输入正确的身份证号码");
          return;
        }
      }

      this.formItemData.creditType.map((i) => {
        if (i.active) {
          this.form.assets.push(i.dictValue);
        }
      });

      if (this.form.assets.length === 0) {
        this.$toast("请至少选择一项资产");
        return;
      }

      if (this.form.credit === "") {
        this.$toast("请选择芝麻分");
        return;
      }

      let datas = {
        ...this.form,
        ...{
          userName: this.form.userName,
        },
      };
      let fun = "";

      if (this.form.channelNo.indexOf("TUOTIAO_FORM_") > -1) {
        fun = "submitAnEntryTouTiao";
      } else {
        datas.idCard = this.form.idCard;
        fun = "submitAnEntry";
      }

      API_Common[fun](datas).then((res) => {
        if (res.code === 200 || res.msg == "insertExisit") {
          if (
            res.data &&
            res.data.isAlert &&
            res.data.isAlert === true &&
            this.form.channelNo != "TUOTIAO_FORM_3"
          ) {
            this.toutiaoShow = true;
            this.companys = res.data.apiNames.join("、");
            this.xxsq = this.xxsq.replace("%s", this.companys);
          } else {
            if (this.product.productName) {
              localStorage.setItem("isSubimit", "YES");
              this.isClickSubmit = true;
              this.showPopProduct = true;
            } else {
              this.submitFormNextGoPage();
            }
          }
        } else {
          this.$toast(res.msg);
        }
      });
    },
    queryFormItem() {
      API_Common.queryFormItem().then((res) => {
        if (res.code == 200) {
          this.formItemData.credit = res.data.credit;
          this.computedActive(this.formItemData);
          this.isFinsh = true;
        } else {
          this.$toast(res.msg);
        }
      });
    },

    getLogo() {
      API_Common.getRegister2Logo(this.form.channelNo).then((res) => {
        if (res.code == 200) {
          this.logo = res.data;
        } else {
          this.$toast(res.msg);
        }
      });
    },

    getRiskWarningText() {
      API_Common.queryRiskWarning(this.form.channelNo).then((res) => {
        if (res.code == 200) {
          const { h5LoanRemindDesc } = res.data;
          this.h5LoanRemindDesc = h5LoanRemindDesc.replace(/[;；]/g, "\n");
        }
      });
    },

    computedActive(objs) {
      Object.keys(objs).forEach((key) => {
        let arr = objs[key];
        arr.map((item) => {
          item.active = false;
        });
      });
    },

    handleChooseCredit(index) {
      this.formItemData.credit.map((item) => {
        item.active = false;
      });
      this.formItemData.credit[index].active = true;
      this.form.credit = this.formItemData.credit[index].dictValue;
      this.$forceUpdate();
    },

    handleSelectCreditInfo(index) {
      if (this.formItemData.creditType[index].active) {
        let arr = this.formItemData.creditType.filter((item) => item.active);

        if (arr.length > 1) {
          this.formItemData.creditType[index].active = false;
        } else {
          this.$toast.fail("至少保留一项");
        }
      } else {
        this.formItemData.creditType[index].active = true;
      }
      this.$forceUpdate();
    },

    handlePopChange(show) {
      this.showPop = show;
    },

    getArea(parentId, index) {
      API_Common.getRegions(parentId).then((res) => {
        if (200 === res.code) {
          this.areaList[index].values = [{ localName: "请选择" }, ...res.data];
          this.areaList = [...this.areaList]; //更新areaList
          this.loading = false;
        } else {
          this.$toast.fail("获取省份失败");
        }
      });
    },

    onCityChange(picker, values, index) {
      if (values[index].localName === "请选择" && index !== 1) {
        this.areaList[1].values = [{ localName: "请选择" }];
      } else {
        if (!index) {
          this.getArea(values[0].id, index + 1); //传参 参数为上层选择的地区的id
        }
      }
    },

    onAreaConfirm(picker) {
      if (
        picker[0].localName === "请选择" ||
        picker[1].localName === "请选择"
      ) {
        this.$toast.fail("请选择完整的省市");
        return false;
      } else {
        this.form.gpsCityName = `${picker[0].localName} ${picker[1].localName}`;
        this.form.provinceId = picker[0].id;
        this.form.cityId = picker[1].id;
        this.showPickerCity = false;
      }
    },

    showProp(type) {
      this.showContent = this[type];
      this.showProtocol = true;
      this.toutiaoShow = false;
      this.loaclTitle =
        type === "zqgz" ? "《知情告知书》" : "《个人信息共享授权书》";
    },

    handleClose() {
      this.showContent = "";
      this.loaclTitle = "";
      this.toutiaoShow = true;
    },

    getProtocol() {
      Promise.all([
        API_Common.getContent({ pageType: 11 }),
        API_Common.getContent({ pageType: 10 }),
      ]).then((res) => {
        this.xxsq = res[0].data.content;
        this.zqgz = res[1].data.content;
      });
    },

    handleFullAgreement(flag) {
      this[`${flag}HeightFlag`] = false;
    },
    submitFormNextGoPage() {
      localStorage.removeItem("isSubimit");
      localStorage.removeItem("isClickAuth");
      this.showPopProduct = false;
      if (this.redirect !== "null") {
        // 为什么需要配置一个重定向链接？
        if (this.$route.path.indexOf("registerEntryNew") > -1) {
          this.$router.push({
            name: "stateWating",
            query: {
              source: this.form.channelNo,
            },
          });
        } else if (this.$route.path.indexOf("registerEntryModel6") > -1) {
          this.$router.push({
            name: "stateWatingModel6",
            query: {
              source: this.form.channelNo,
            },
          });
        } else {
          window.location.href =
            this.redirect + "?source=" + this.form.channelNo;
        }
      } else {
        //模版6 不做跳转限制
        if (this.$route.path.indexOf("registerEntryModel6") > -1) {
          this.$router.push({
            name: "stateWatingModel6",
            query: {
              source: this.form.channelNo,
            },
          });
        } else {
          this.$router.push({
            name: "PassedSuccess",
            query: {
              source: this.form.channelNo,
            },
          });
        }
      }
    },
    handleGoOn() {
      this.submitFormNextGoPage();
    },
    //     点击推荐产品立即申请
    rpApplyBtndDidAction() {
      if (!this.rpChecked) {
        this.$toast("请勾选同意协议及政策");
        return;
      }
      //跳转到产品详情
      // window.location.href = this.product.linkUrl;
      // this.showPopProduct = false;
      // localStorage.removeItem("isClickAuth");
      this.handleGoLink(this.product);
    },
    //点击关闭弹窗
    reCloseBtnDidAction() {
      this.showPopProduct = false;
      if (this.isClickSubmit) {
        this.submitFormNextGoPage();
      } else {
        this.showAlertView();
      }
    },
    showAlertView() {
      Dialog.confirm({
        title: "确认退出",
        message: "您还有一步就可完成申请\n是否确认退出?",
        confirmButtonText: "继续填写",
        cancelButtonText: "确认退出",
        confirmButtonColor: "#3366FF",
      })
        .then(() => {
          // on confirm
        })
        .catch(() => {
          this.showPopProduct = true;
        });
    },
    requestPopupProduct() {
      API_Common.getPopupProductList(this.form.channelNo).then((res) => {
        if (res.code == 200) {
          var total = res.data.total;
          var list = res.data.list;
          console.log(res);
          if (total > 0) {
            this.product = list[0];
            this.product.loanRate = this.product.loanRate + "%";
            this.showBackBtn = true;
          }
        }
      });
    },
    handleProtocol(pageType) {
      window.location.href = "/protocol.html?pageType=" + pageType;
      localStorage.setItem("isClickAuth", "YES");
    },
    backBtnDidAction() {
      if (this.product.productName) {
        this.showPopProduct = true;
      }
    },
    handleGoLink(item) {
      API_Common.buried({
        productId: item.productId,
        showPage: 5,
      }).then((res) => {
        if (200 === res.code) {
          setTimeout(function () {
            window.location.href = item.linkUrl;
          }, 500);
        } else {
          window.location.href = item.linkUrl;
        }
      });
    },
  },
};
</script>

<style scoped>
@import "../css/popupProduct.css"; /*引入公共样式*/

iframe {
  display: block;
}
</style>

<style lang="scss" scoped>
.container {
  height: 100vh;
  overflow: auto;
  background: url("../assets/images/borrow_much_bg.png") no-repeat top left;

  background-size: 100%;
  padding: 0.3rem 0.35rem;

  .van-overlay {
    z-index: 3;
  }
}

.round-con {
  width: 6.64rem;
  height: 3.32rem;
  overflow: hidden;
  margin: 0 auto;
}

.round-div {
  width: 6.64rem;
  height: 6.64rem;
  border-radius: 50%;
  z-index: 1;
  transform: rotate(5deg);
}

.address {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 0.3rem;
  right: 0.2rem;
  top: 0.15rem;
  font-size: 0.24rem;
  font-weight: 400;
  color: #3a3a3a;
  padding: 0.1rem;
  z-index: 2;
}

.icon-position {
  width: 0.28rem;
  height: 0.28rem;
  margin-right: 0.04rem;
}

.step-line-move {
  transform: rotate(157deg);
  transition: transform 2s ease-in-out;
}

.round-img {
  width: 6.64rem;
  height: 6.64rem;
}

.scale {
  top: 0;
  left: 0;
  width: 6.64rem;
  height: 6.64rem;
}

.scale > li {
  width: 0.07rem;
  height: 0.21rem;
  background: #44aefe;
  position: absolute;
  left: 48.8%;
  top: 0.4rem;
  transform-origin: center 2.94rem;
  z-index: 0;
}

.line-solid-bg {
  width: 3.84rem;
  height: 1.42rem;
  left: 50%;
  margin-left: -1.92rem;
  top: 0.1rem;
}

.interest-free {
  font-size: 0.32rem;
  font-family: PingFangSC, PingFangSC-Regular;
  color: #989fa6;
  margin: 0.3rem 0;
}

.round-cont-info {
  top: 1rem;
  left: 0;
  width: 100%;
  font-size: 0.32rem;
  font-family: PingFangSC, PingFangSC-Regular;
  color: #989fa6;

  span {
    font-size: 1rem;
    font-weight: 900;
    color: #2857f6;
    z-index: 2;
  }
}

.logo {
  width: 1.2rem;
  height: 0.28rem;
  margin-bottom: 0.2rem;
}

.register-form {
  background: #ffffff;
  border-radius: 0.16rem;
  padding: 0.3rem;
}

.inp-elem-box {
  margin-bottom: 0.15rem;
}

.inp-elem {
  border-radius: 0.08rem;
  padding: 0.24rem 0.26rem;
  background-color: #f9f9f9;
}

.van-hairline--top-bottom::after {
  display: none;
}

.label-left {
  font-size: 0.28rem;
  font-weight: 400;
  color: #3a3a3a;

  > span {
    color: #c3c2c6;
  }

  > small {
    display: block;
    margin: 0.1rem 0;
  }

  .highlight {
    color: #ff7f35;
  }
}

.tags {
  font-size: 0.28rem;
  color: #c3c2c6;
}

.van-col {
  > div {
    height: 0.6rem;
    width: 100%;
    margin-top: 0.1rem;
    background: #ecece7;
    border: 0.02rem solid #ecece7;
    border-radius: 0.06rem;
  }
}

.tags .active {
  > div {
    background: #fff4e6;
    border-color: #fb8d0b;
    color: #fb8d0b;
  }
}

.btn-submit {
  margin: 0.3rem 0;
  display: block;
  width: 100%;
  height: 0.9rem;
  line-height: 0.9rem;
  background: linear-gradient(90deg, #ffbd56, #ff7e34);
  font-size: 0.32rem;
  font-weight: 500;
  color: #ffffff;
  border-radius: 0.08rem;
}

.btn-submit-tips {
  font-size: 0.24rem;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  color: #8884e9;

  > p {
    margin-left: 0.05rem;
  }
}

.bottom {
  margin: 0.3rem -0.1rem 0;
  font-family: PingFangSC, PingFangSC-Regular;
  font-size: 0.24rem;
  font-weight: 400;
  text-align: center;
  color: #8884e9;
  line-height: 0.4rem;
  display: block;
  white-space: pre-line;
}

.toutiao-show-con {
  padding: 0 0.4rem;
  height: 100%;
  overflow: hidden;
}

.toutiao-pup-title {
  margin: 0.4rem 0 0.24rem;
  font-size: 0.26rem;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  color: #959595;
  text-align: justify;

  > span {
    color: #f88b2d;
  }
}

.toutiao-pup-protocol {
  height: calc(100% - 3.5rem);
  overflow: auto;

  > div {
    height: 45%;
    background: #f7f7f7;
    border-radius: 0.24rem;
    padding: 0.26rem;
    margin-bottom: 0.2rem;
    overflow: hidden;
    transition: height 1s linear;
  }

  > div.expand-height {
    height: auto;
  }

  h4 {
    margin-bottom: 0.2rem;
    font-size: 0.3rem;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 600;
    color: #3c3e41;
  }

  .islink {
    padding: 0.05rem;
    right: 0;
    bottom: 0;
    width: 1.7rem;
    box-shadow: 0 0.02rem 0.2rem 0 rgba(0, 0, 0, 0.3);
  }
}

.go-on {
  bottom: 0;
  left: 0;
  right: 0;
  height: 1.8rem;
  background: #ffffff;
  box-shadow: 0 0.02rem 0.2rem 0 rgba(0, 0, 0, 0.3);
  padding: 0.16rem 0.4rem 0.68rem;
  font-size: 0.3rem;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 500;
  color: #ffffff;

  > p {
    line-height: 0.96rem;
    background: linear-gradient(90deg, #ffbd56, #ff7e34);
  }
}

.show-protocol-con {
  height: 100%;
  padding: 0.4rem 0.4rem 0;

  > h4 {
    margin-bottom: 0.2rem;
    font-size: 0.3rem;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 600;
    color: #3c3e41;
  }
}

.show-protocol-info {
  height: calc(100% - 1.45rem);
  overflow: auto;
}

.show-protocol-btn {
  left: 0;
  right: 0;
  border-top: 0.02rem solid #e3e3e3;
  font-size: 0.3rem;
  height: 0.8rem;
  line-height: 0.8rem;
  margin: 0 -0.4rem;
  font-family: PingFangSC, PingFangSC-Regular;
}
</style>
